<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/secretary/circulars')"
        />
      </template>
    </Toolbar>
    <div class="form-card ">
      <div class="mb-3">
        <label for="title" class="form-label ">
          العنوان
        </label>
        <input
          type="text"
          class="form-control"
          id="title"
          v-model="body.title"
          :class="{ 'is-invalid': isInvalid && !body.title }"
        />
      </div>
      <div class="mb-3">
        <label for="body" class="form-label ">
          التفاصيل
        </label>
        <b-form-textarea
          id="textarea-small"
          name="body"
          v-model="body.body"
          :class="{ 'is-invalid': isInvalid && !body.body }"
          placeholder=""
        ></b-form-textarea>
      </div>

      <div class="mb-3">
        <label for="branchesId" class="form-label ">
          الفرع
        </label>

        <select
          class="form-select form-control"
          id="branchesId"
          name="branchesId"
          v-model.number="body.branchesId"
        >
          <option :value="null">الكل</option>
          <option
            v-for="item of branchesList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="administrationId" class="form-label ">
          الاداره
        </label>

        <select
          class="form-select form-control"
          id="administrationId"
          name="administrationId"
          v-model.number="body.administrationId"
        >
          <option :value="null">الكل</option>
          <option
            v-for="item of administrationList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="sectionsId" class="form-label ">
          القسم
        </label>

        <select
          class="form-select form-control"
          id="sectionsId"
          name="sectionsId"
          v-model.number="body.sectionsId"
        >
          <option :value="null">الكل</option>
          <option
            v-for="item of sectionsList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="employeesId" class="form-label ">
          المسمي الوظيفي
        </label>

        <select
          class="form-select form-control"
          id="employeesId"
          name="employeesId"
          v-model.number="body.employeesId"
        >
          <option :value="null">الكل</option>
          <option
            v-for="item of employeesList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="usersId" class="form-label ">
          اسم الموظف
        </label>
        <select
          class="form-select form-control"
          id="usersIds"
          name="usersIds"
          v-model.number="body.usersIds"
        >
          <option :value="null">الكل</option>

          <option v-for="item of usersList" :key="item.id" :value="item.id">{{
            item.name
          }}</option>
        </select>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          :disabled="disabledAdd"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          @click="save()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const user = JSON.parse(localStorage.admin);

    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: `${ye}-${mo}-${da}`,
        usersId: user.id,
        usersIds: null,
        title: null,
        body: null,
        branchesId: null,
        administrationId: null,
        sectionsId: null,
        employeesId: null,
      },
      id: null,
      branchesList: [],
      usersListConst: [],
      usersList: [],
      administrationList: [],
      sectionsList: [],
      employeesList: [],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (this.body.title && this.body.body) {
        this.disabledAdd = true;
        if (this.body.usersIds) {
          this.usersList = [this.body.usersIds];
        }
        this.$http
          .post(`circulars`, {
            ...this.body,
            list: this.usersList,
          })
          .then(
            () => {
              this.disabledAdd = false;
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم الاضافة بنجاح',
                life: 3000,
              });
              this.$router.push('/admin/secretary/circulars');
            },
            (err) => {
              this.disabledAdd = false;

              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },

    getUsersList() {
      if (this.body.branchesId) {
        const list = [];
        for (const item of this.usersListConst) {
          if (item.branches.find((el) => el.id == this.body.branchesId)) {
            list.push(item);
          }
        }

        this.usersList = list;
      } else {
        this.usersList = this.usersListConst;
      }

      if (this.body.administrationId) {
        this.usersList = this.usersList.filter(
          (el) => el.administrationId.id == this.body.administrationId,
        );
      }

      if (this.body.sectionsId) {
        this.usersList = this.usersList.filter(
          (el) => el.sectionsId.id == this.body.sectionsId,
        );
      }

      if (this.body.employeesId) {
        this.usersList = this.usersList.filter(
          (el) => el.employeesId.id == this.body.employeesId,
        );
      }
    },
  },
  created() {
    if (!this.$checkRoles('circularsAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });

    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
      this.usersListConst = res.data;
    });

    this.$http.get(`administration`).then((res) => {
      this.administrationList = res.data;
    });
    this.$http.get(`sections`).then((res) => {
      this.sectionsList = res.data;
    });
    this.$http.get(`employees`).then((res) => {
      this.employeesList = res.data;
    });
  },
  watch: {
    'body.branchesId'(val) {
      this.getUsersList();
    },
    'body.administrationId'(val) {
      this.$http.get(`sections?administrationId=${val}`).then((res) => {
        this.sectionsList = res.data;
      });
      this.getUsersList();
    },
    'body.sectionsId'(val) {
      this.$http.get(`employees?sectionsId=${val}`).then((res) => {
        this.employeesList = res.data;
      });
      this.getUsersList();
    },
    'body.employeesId'(val) {
      this.getUsersList();
    },
  },
};
</script>

<style>
.firstSupport label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
